import styled from 'styled-components';
import logo from '../../../logo.png';

const HeaderStyled = styled.div`
    position: relative;
`;

const TopBar = styled.div`
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #3f3f3f;
    max-width: 1200px;
    left: 50%;
    transform: translateX(-50%);
    height: 90px;
    z-index: 2;
    padding: 0 10px 40px 0;
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.75);
    border-radius: 0 0 10px 10px;
    top: 0;
    width: calc(100% - 20px);

    h1{
        font-size: 40px;
        color: white;
    }
`;

const AppLogoStyled = styled.img`
    height: 90px;
    aspect-ratio: 1;
`;

const SearchBoxStyled = styled.div`
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);    
    width: calc(50% - 60px);
    z-index: 3;

    input{
        width: 100%;
        text-align: center;
        height: 60px;
        font-size: 30px;
        border-radius: 50px;
        border: none;
        padding: 0px 20px;
    }
`;

const Header = ({ setSearchTerm }) => {

    return(
        <HeaderStyled>
            <TopBar>
                <AppLogoStyled src={logo} className="App-logo" alt="logo" />
                <h1>Musikwünsche:</h1>
            </TopBar>
            <SearchBoxStyled>
                <input type="text" placeholder="Suchen..." onChange={(e)=>setSearchTerm(e.target.value)}/>
            </SearchBoxStyled>
        </HeaderStyled>
    )
};

export default Header;
