import bg from './bg.jpg';
import './App.css';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ToggleButton from './components/organisms/ToggleButton/ToggleButton';
import TrackEntry from './components/molecules/TrackEntry/TrackEntry';
import Header from './components/organisms/Header/Header';
import logo from './logo.png';

const backendUrl = 'https://musicwish-api.musicandlight-ahrensburg.de/api';

const AppScreenStyled = styled.div`
    background-color: #0f0f0fcf;
    padding: 0px 20px 20px 20px;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 80vh;
`;

const BackgroundColorStyled = styled.div`
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.4;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const requestNewSong = (title, author, trackid, genre) => {
    console.log("requestNewSong: " + title + " - " + author + " ");
    fetch(backendUrl + "/addwish?Title=" + title + "&Author=" + author + "&TrackID=" + trackid + "&Genre=" + genre, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => response.json())
    .then(data => {
        console.log('Success:', data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
}


const NewRequest = ({onRequestNewSong, tracks, playedTracks, searchTerm}) => {
    return (
        tracks && tracks.length>0 && tracks.map(
            (item)=>
            {
                playedTracks && playedTracks.length>0 && playedTracks.map((playedTrack) => {
                    if(playedTrack.TrackID === item.Id){
                        item.Wished = true;
                    }
                });
                if(item.Wished === undefined){
                    item.Wished = false;
                }

                //Remove all ÄÖÜ, spaces and apostrophes
                var cleanedSearchTerm = searchTerm.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                var cleanedItemTitle = item.Title.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                var cleanedItemAuthor = item.Author.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                var cleanedItemGenre = item.Genre.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                if(cleanedSearchTerm.length === 0 || cleanedItemTitle.includes(cleanedSearchTerm) || cleanedItemAuthor.includes(cleanedSearchTerm) || cleanedItemGenre.includes(cleanedSearchTerm)) {
                    if(item.Title !== "~1,-4" && item.Genre !== "Lena Musik" && item.Genre !== "_SORTIEREN UND TAGGEN") {
                        return (
                            <TrackEntry key={item.Id} item={item} showbutton={true} onRequestNewSong={onRequestNewSong}/>
                        )
                    }
                }
                return null;
            }
        )
    )
}

const ExistingWishesList = ({playedTracks, searchTerm}) => {
    return (
        playedTracks && playedTracks.Wishes && playedTracks.Wishes.length > 0 && playedTracks.Wishes.map(
            (item)=>
            {
                //Remove all ÄÖÜ, spaces and apostrophes
                var cleanedSearchTerm = searchTerm.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                var cleanedItemTitle = item.Title.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                var cleanedItemAuthor = item.Author.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                if(cleanedSearchTerm.length === 0 || cleanedItemTitle.includes(cleanedSearchTerm) || cleanedItemAuthor.toLowerCase().includes(cleanedSearchTerm)) {
                    if(item.Title !== "~1,-4" && item.Genre !== "Lena Musik" && item.Genre !== "_SORTIEREN UND TAGGEN") {
                        return (
                            <TrackEntry key={item.Created + item.Title} item={item} showCreated/>
                        )
                    }
                }
            }
        )
    )
};

const PlayedWishesList = ({playedTracks, searchTerm}) => {
    return (
        playedTracks && playedTracks.Played && playedTracks.Played.length > 0 && playedTracks.Played.map(
            (item)=>
            {
                //Remove all ÄÖÜ, spaces and apostrophes
                var cleanedSearchTerm = searchTerm.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                var cleanedItemTitle = item.Title.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                var cleanedItemAuthor = item.Author.toLowerCase().replace(/[äöüÄÖÜ]/g, function(x) {
                    return x === 'ä' ? 'ae' : x === 'ö' ? 'oe' : x === 'ü' ? 'ue' : x === 'Ä' ? 'Ae' : x === 'Ö' ? 'Oe' : x === 'Ü' ? 'Ue' : x;
                }).replace(/ /g, '').replace(/'/g, '').replace(/[(){}\[\]]/g, '');

                if(cleanedSearchTerm.length === 0 || cleanedItemTitle.includes(cleanedSearchTerm) || cleanedItemAuthor.toLowerCase().includes(cleanedSearchTerm)) {
                    if(item.Title !== "~1,-4" && item.Genre !== "Lena Musik" && item.Genre !== "_SORTIEREN UND TAGGEN") {
                        return (
                            <TrackEntry key={item.Created + item.Title} item={item} showLastEdited/>
                        )
                    }
                }
            }
        )
    )
};

const ExistingWishes = ({playedTracks, searchTerm}) => {
    return (
        <div>
            <h2>Anstehende Wünsche</h2>
            <ExistingWishesList playedTracks={playedTracks} searchTerm={searchTerm}/>

            <h2>Bereits gespielte Wünsche</h2>
            <PlayedWishesList playedTracks={playedTracks} searchTerm={searchTerm}/>
        </div>
    )
}

const ContentBoxStyled = styled.div`
    padding-top: 180px;
    padding-bottom: 40px;
`;


function App() {
    const [tracks,setTracks]=useState([]);
    const [playedTracks,setPlayedTracks]=useState([]);
    const [searchTerm,setSearchTerm]=useState('');
    const [showWishes,setShowWishes]=useState(false);

    const onRequestNewSong = item => {
        requestNewSong(item.Title, item.Author, item.Id, item.Genre);
        item.Wished = true;
        setTracks([...tracks]);
        getPlayedTracks();
        updateOptions();
    }

    const updateScreen = () => {
        getPlayedTracks();
        setTracks([...tracks]);
        updateOptions();
    }

    const getPlayedTracks=()=>{
        fetch(backendUrl + "/getcurrentwishes", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(function(response){
                return response.json();
            })
            .then(function(myJson) {
                setPlayedTracks(myJson.Items);
                updateOptions();
            });
      }
      useEffect(()=>{
        getPlayedTracks()
      },[])

    const getData=()=>{
      fetch(backendUrl + '/tracks'
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }
      )
        .then(function(response){
          return response.json();
        })
        .then(function(myJson) {
          setTracks(myJson.Items)
        });
    }
    useEffect(()=>{
      getData()
    },[])


    const updateOptions = () => {
        tracks && tracks.length>0 && tracks.map(
            (track)=>
            {
                playedTracks && playedTracks.Wishes && playedTracks.Wishes.length>0 && playedTracks.Wishes.map((playedTrack) => {
                    if(playedTrack.TrackID == track.Id){
                        track.Wished = true;
                        return true;
                        setTracks([...tracks]);
                    }
                });
                return null;
            }
        );
    }
    
    updateOptions();

    return (
        <AppScreenStyled>
            <BackgroundColorStyled>
                <img src={bg} alt=''/>
            </BackgroundColorStyled>
            <Header setSearchTerm={setSearchTerm}/>
            <ToggleButton showWishes={showWishes} setShowWishes={setShowWishes} updateScreen={updateScreen} />
            <ContentBoxStyled>
                { 
                    showWishes ? <ExistingWishes playedTracks={playedTracks} searchTerm={searchTerm}/> : <NewRequest onRequestNewSong={onRequestNewSong} tracks={tracks} playedTracks={playedTracks} searchTerm={searchTerm}/>
                }
            </ContentBoxStyled>
        </AppScreenStyled>
    );
  }
  
  export default App;
