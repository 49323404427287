import styled from 'styled-components';

const EntryStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 30px 10px 10px;
    padding: 10px 25px 10px 10px;
    border: 1px solid white;
    border-radius: 5px;
    position: relative;
    color: white;
    backdrop-filter: blur(10px);
    transition: 0ms.3s ease-in-out;

    &.wished--true {
        opacity: 0.5;
        //padding-top: 30px;
        overflow: hidden;
        padding-right: 100px;

        button{
            display: none;
        }

        &::after{
            content: "Bereits gewünscht";
            position: absolute;
            top: 12px;
            right: 10px;
            font-size: 12px;
            transform: rotate(10deg);
        }
    }
`;

const AddButtonStyled = styled.button`
    background-color: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    aspect-ratio: 1;
    font-size: 30px;
    color: white;
    box-shadow: 0px 0px 20px 0px rgba(215,150,0,1), 0px 0px 10px 0px rgba(200,100,0,1);
`;

const AuthorStyled = styled.p`
    font-size: 16px;
    font-weight: 200;
    margin: 0;
    text-align: left;

    i{
        margin-left: 10px;
    }
`;

const TitleStyled = styled.p`
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    text-align: left;
`;

const FormattedDate = ({date}) => {
    const dateObj = new Date(date);
    dateObj.setHours(dateObj.getHours());
    return dateObj.toLocaleDateString() + " | " + dateObj.toLocaleTimeString();
}

const TrackEntry = ({ item, onRequestNewSong, showbutton = false, showCreated = false, showLastEdited = false }) => {

    return (
        <EntryStyled key={item.Id} className={"wished--" + item.Wished}>
            <TitleStyled>{item.Title}</TitleStyled>
            <AuthorStyled>{item.Genre && <i> {item.Genre} - </i>}{item.Author}
                {showCreated && 
                    <i>
                        <FormattedDate date={item.Created}></FormattedDate>
                    </i>
                }
                {showLastEdited && 
                    <i>
                        <FormattedDate date={item.LastEdited}></FormattedDate>
                    </i>
                }
            </AuthorStyled>
            {showbutton && 
                <AddButtonStyled onClick={() => {onRequestNewSong(item);}}>+</AddButtonStyled>
            }
        </EntryStyled>
    );
};

export default TrackEntry;
