import styled from 'styled-components';

const ToggleButtonStyled = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    bottom: 0px;
    left: 0;
    width: 100vw;
    height: 40px;
    background-color: #3f3f3f;
    color: white;
    z-index: 2;

    button {
        height: 100%;
        padding: 10px 20px;
        width: 50%;
        border-radius: 50px;
        border: none;
        background-color: transparent;
        z-index: 1;
        transition: 0ms.2s ease-in-out;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        &:first-of-type{
            ${props => props.showWishes ? "color: #d89803;" : "color: black;"}
        }

        &:last-of-type{
            ${props => props.showWishes ? "color: black;" : "color: #d89803;"}
        }
    }

    span{
        position: absolute;
        width: 50%;
        height: 100%;
        background-color: #d89803;
        border-radius: 50px;
        left: 0px;
        transition: 0.2s ease-in-out;

        ${props => props.showWishes ? "transform: translateX(100%);" : "transform: translateX(0%);"}
    }
`;

const ToggleButton = ({ showWishes, setShowWishes, updateScreen }) => {
    return (
        <ToggleButtonStyled showWishes={showWishes}>
            <button onClick={() => {
                    setShowWishes(false);
                    updateScreen();
            }}>Neuer Wunsch</button>
            <button onClick={() => {
                setShowWishes(true);
                updateScreen();
            }}>Wünsche</button>
            <span></span>
        </ToggleButtonStyled>
    );
}

export default ToggleButton;
